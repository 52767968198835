import React from "react";
import Layout from "../components/Layout";
import SectionTitle from "../components/SectionTitle";
import BlogFeaturedPreview from "../components/BlogFeaturedPreview";
import BlogRoll from "../components/BlogRoll";

export const BlogListPageTemplate = () => {
  return (
    <div className="blog-list">
      <BlogFeaturedPreview />

      <section className="section">
        <SectionTitle
          className="list-title"
          heading="PUBLISHED"
          subHeading="RECENTLY"
        />
        <div className="container">
          <BlogRoll />
        </div>
      </section>
    </div>
  );
};

const BlogListPage = () => (
  <Layout title="Blogs">
    <BlogListPageTemplate />
  </Layout>
);

export default BlogListPage;
