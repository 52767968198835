import React, { useEffect, useState } from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import LoadMoreButton from "./LoadMoreButton";
import { getSlugToken, isNotDraft, isPublished } from "../utils/content-utils";

const BlogRollTemplate = ({ blogs = [], blogCategories = [] }) => {
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [page, setPage] = useState(1);
  const pageSize = 9;
  useEffect(() => {
    setPage(1);
  }, [categoryIndex]);

  const selectedCategory = blogCategories[categoryIndex];
  const allItems =
    blogs
      .filter(isPublished)
      .filter((blog) => blog.blog_category === selectedCategory.slug) || [];

  const visibleItem = allItems.slice(0, pageSize * page);
  const isLoadMoreEnabled = pageSize * page <= allItems.length;

  return (
    <div className="blog-roll">
      <div className="categories">
        {blogCategories &&
          blogCategories.map((category, index) => (
            <button
              className={`button is-text category is-uppercase ${
                index === categoryIndex ? "active" : ""
              }`}
              key={category.slug}
              onClick={() => setCategoryIndex(index)}
            >
              {category.title}
            </button>
          ))}
      </div>

      <div className="list">
        {visibleItem.map((blog, index) => (
          <div className="item column" key={blog.slug}>
            <Link to={blog.slug}>
              <div className="ratio-1-1">
                <PreviewCompatibleImage
                  style={{ position: "absolute", borderRadius: "0.5rem" }}
                  imageInfo={blog.featuredimage}
                  alt={blog.title}
                />
              </div>
              <p className="has-text-weight-bold">{blog.title}</p>
            </Link>
          </div>
        ))}
      </div>
      <LoadMoreButton
        enabled={isLoadMoreEnabled}
        onClick={() => setPage(page + 1)}
      />
    </div>
  );
};

const BlogRoll = ({ data }) => {
  const { blogs: blogEdges, categories } = data;
  const { edges } = blogEdges;
  const blogs = edges
    .map((edge) => ({
      slug: edge.node.fields.slug,
      ...edge.node.frontmatter,
    }))
    .filter(isNotDraft);

  const blogCategories = categories.edges.map((edge) => ({
    slug: getSlugToken(edge.node.fields.slug),
    title: edge.node.frontmatter.title,
  }));

  // console.debug(blogs);
  // console.debug(blogCategories);

  return <BlogRollTemplate blogs={blogs} blogCategories={blogCategories} />;
};

export default (props) => (
  <StaticQuery
    render={(data) => <BlogRoll data={data} {...props} />}
    query={graphql`
      query BlogRoll {
        blogs: allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { type: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                draft
                date
                title
                blog_category
                featuredimage
              }
            }
          }
        }
        categories: allMarkdownRemark(
          sort: { fields: [frontmatter___priority] }
          filter: { frontmatter: { type: { eq: "blog-category" } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
      }
    `}
  />
);
