import React, { useState } from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import Slider from "react-slick";
import { useWindowSize } from "../hooks/useWindowSize";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import SectionTitle from "./SectionTitle";
import { getSlugToken, isNotDraft, isPublished } from "../utils/content-utils";

const BlogFeaturedPreviewTemplate = ({
  featuredBlogs = [],
  title = "",
  enabled = false,
}) => {
  const { width } = useWindowSize();
  const visibleFeaturedBlogs = featuredBlogs.filter(isPublished);
  const [activeIndex, setActiveIndex] = useState(0);
  const activeBlogs = visibleFeaturedBlogs[activeIndex];

  if (!enabled) return null;
  if (visibleFeaturedBlogs.length === 0) return null;

  const isMobile = width <= 900;

  const sliderSetting = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className="blog-list-featured section">
      <SectionTitle heading={title} subHeading="FEATURED" />
      <div className=" container">
        {isMobile ? (
          <div className="slider">
            <Slider {...sliderSetting}>
              {visibleFeaturedBlogs.map((blog, index) => (
                <Link to={blog.slug} key={blog.slug}>
                  <div className="item">
                    <div className="ratio-1-1">
                      {blog.featuredimage ? (
                        <PreviewCompatibleImage
                          imageInfo={blog.featuredimage}
                          style={{ position: "absolute", borderRadius: "1rem" }}
                        />
                      ) : null}
                    </div>
                    <p>{blog.title}</p>
                    <div className="label-2">{blog.categoryName}</div>
                  </div>
                </Link>
              ))}
            </Slider>
          </div>
        ) : (
          <div className="columns">
            <div className="column is-half preview">
              {activeBlogs ? (
                <Link to={activeBlogs.slug}>
                  <div className="ratio-1-1">
                    {activeBlogs.featuredimage ? (
                      <PreviewCompatibleImage
                        imageInfo={activeBlogs.featuredimage}
                        style={{ position: "absolute", borderRadius: "1rem" }}
                      />
                    ) : null}
                  </div>
                  <p className="has-text-centered">{activeBlogs.title}</p>
                  <p className="label-2 has-text-centered">
                    {activeBlogs.categoryName}
                  </p>
                </Link>
              ) : null}
            </div>
            <div className="column is-half">
              {visibleFeaturedBlogs.map((blog, index) => (
                <Link to={blog.slug} key={blog.slug}>
                  <div
                    className={`item ${activeIndex === index ? "active" : ""}`}
                    onMouseOver={() => setActiveIndex(index)}
                  >
                    <p>{blog.title}</p>
                    <div className="label-2">{blog.categoryName}</div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

const BlogFeaturedPreview = ({ data }) => {
  const { featured: fNode, blogs: bEdges, categories: cEdges } = data;

  const categories = cEdges.edges.map((edge) => ({
    slug: edge.node.fields.slug,
    ...edge.node.frontmatter,
  }));
  const blogs = bEdges.edges.map((edge) => ({
    slug: edge.node.fields.slug,
    ...edge.node.frontmatter,
  }));
  const featuredBlogSlugs =
    fNode.frontmatter.featured_section.featured_posts || [];
  const featuredBlogs = [];
  featuredBlogSlugs.forEach((fSlug) => {
    const fBlog = blogs.find((blog) => fSlug === getSlugToken(blog.slug));
    if (fBlog && isNotDraft(fBlog)) {
      const category = categories.find(
        (cat) => getSlugToken(cat.slug) === fBlog.blog_category
      );
      fBlog.categoryName = category && category.title;
      featuredBlogs.push(fBlog);
    }
  });

  const { enabled, title } = fNode.frontmatter.featured_section;

  return (
    <BlogFeaturedPreviewTemplate
      enabled={enabled}
      title={title}
      featuredBlogs={featuredBlogs}
    />
  );
};

export default (props) => (
  <StaticQuery
    render={(data) => <BlogFeaturedPreview data={data} {...props} />}
    query={graphql`
      query BlogFeaturedPreview {
        featured: markdownRemark(
          frontmatter: { templateKey: { eq: "blog-list" } }
        ) {
          frontmatter {
            featured_section {
              featured_posts
              enabled
              title
            }
          }
        }
        blogs: allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { type: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                draft
                date
                title
                blog_category
                featuredimage
              }
            }
          }
        }
        categories: allMarkdownRemark(
          sort: { fields: [frontmatter___priority] }
          filter: { frontmatter: { type: { eq: "blog-category" } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
      }
    `}
  />
);
